import styles from '../styles/Home.module.scss'
import {Box, Button, Typography} from "@mui/material";
import TextWithSideImage from "../components/shared/TextWithSideImage";
import HomeIntro from "../components/sections/HomeIntro";
import Testimonials from "../components/shared/Testimonials";
import Link from "next/link";
import Head from "next/head";
import seo from "../constants/seo.json";
import Image from "next/image";
import PageContainer from "../components/layouts/PageContainer";
import GetInTouch from "../components/shared/GetInTouch";

export default function Home() {
    const meta = seo.home;

    return (
        <div className={styles.container}>

            <Head>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description}/>
                <meta name="keywords" content={meta.keywords}/>
            </Head>

            <HomeIntro/>

            <TextWithSideImage textDirection={'right'}
                               primaryText={'ScreenStak™ two iPhones'}
                               text={'ScreenStak™ two iPhones or two Android phones or one iPhone and one Android phone and display images or play videos over 2X the display size.'}
                               image={'/images/two-iphones.png'}
                               imageAlt={'Make Large Screens using multiple phones'}
                               imageWidth={554}
                               imageHeight={604}
            />

            <TextWithSideImage textDirection={'left'}
                               primaryText={'ScreenStak™ an iPad with an Android phone'}
                               text={'ScreenStak™ app allows larger displays to be created across heterogeneous devices.'}
                               image={'/images/cross-devices.png'}
                               imageAlt={'Our App is capable with TV Screens'}
                               imageWidth={604}
                               imageHeight={604}
            />

            <TextWithSideImage textDirection={'right'}
                               primaryText={'ScreenStak™ smart TVs'}
                               text={'Build Video walls without requiring any cabling. Any number of TV screens can be on the video wall, all you need to do is install the ScreenStak™ app on each one of them.'}
                               image={'/images/tv-screens.png'}
                               imageAlt={'Share with different kinds of devices'}
                               imageWidth={454}
                               imageHeight={604}
            />

            <Testimonials/>

            <GetInTouch />
        </div>
    )
}
