import {IconButton, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import Image from "next/image";
import styles from '../../styles/Testimonials.module.scss'
import {ArrowBackIos, ArrowForwardIos, Star} from "@mui/icons-material";

export default function Testimonials() {
    const matchesMobile = useMediaQuery('(max-width:1000px)');
    const matchesSmallerScreen = useMediaQuery('(max-width:1800px)');

    let usersOnScreenAtTheSameTime = 3

    if(matchesSmallerScreen)
        usersOnScreenAtTheSameTime = 2

    if(matchesMobile)
        usersOnScreenAtTheSameTime = 1

    const peopleReviews = [
        {
            name: 'Samantha Lee',
            position: 'Creative Director',
            stars: 5,
            review: 'I was at a tradeshow with a few of my colleagues. We needed to attend a Zoom video conference. Since we did not have a laptop with us, we decided to ScreenStak™ our iPhones and Android phones in a 3 x 2 matrix and were able to see 60 person video conference easily. To view a 60 person video conference on a single cell phone screen would have been very impractical. ScreenStak™ app is a game changer! ',
            image: '/images/testimonials/women.svg'
        },
        {
            name: 'John Martinez',
            position: 'Chief Information Officer',
            stars: 5,
            review: 'Myself and my wife were on a long plane ride. We wanted to watch a movie together that I had downloaded on my iPhone. During the flight we ScreenStaked our phones, then I started the movie playback on my phone and wallah we could both see the movie on the combined screen across both our iPhones. The best part is that I didn’t even need the plane’s Wi-Fi for this to work.',
            image: '/images/testimonials/men.svg'
        },
        {
            name: 'Linda Green',
            position: 'Accountant',
            stars: 5,
            review: 'I usually have to see large spreadsheets. Sometimes I try to see them on my Android phone also. I use pinch and zoom to move around the spreadsheet and see specific cells. Sometimes, I can see the cells but then I lose the context of where I was. When I learnt about ScreenStak™ app, I tried it between mine and a friend’s iPhone. It was great, I could see much bigger spreadsheet and no pinch and zoom required.',
            image: '/images/testimonials/women.svg'
        },
        {
            name: 'Dr. Ali Meherban',
            position: 'Ophthalmologist',
            stars: 5,
            review: 'I grade retinal images for multiple ophthalmology practices. Sometimes I receive retinal images via text. It’s hard to see such images on my iPhone’s small display. If I do pinch and zoom, I loose the overall context of the image. A few days ago I discovered ScreenStak™ app. Using this app I can now see the retinal image across two iPhones easily. As the image is now twice as large, I don’t need to pinch and zoom any more.',
            image: '/images/testimonials/men.svg'
        }
    ];

    // split testimonials into chunks by 2 elements and print them in rows
    const peopleReviewsSplit = (arr, size) => {
        const newArr = [];
        for (var i = 0; i < arr.length; i += size) {
            newArr.push(arr.slice(i, i + size));
        }
        return newArr;
    }

    // itireate over the chunks and print them in rows
    const testimonials = peopleReviewsSplit(peopleReviews, usersOnScreenAtTheSameTime).map((row, index) => {
        return (
            <Box className={styles.screenContainer} key={index}>
                {row.map((person, index) => {
                    return (
                        <Box className={styles.personContainer} key={person.name}>
                            <Box className={styles.text}>
                                <Typography component="div" className={styles.review}>
                                    {person.review}
                                </Typography>

                                <Box className={styles.imageContainer}>
                                    <Image src={person.image} width={90} height={90} alt={person.name}/>
                                </Box>

                                <Typography component="h3" className={styles.name}>
                                    {person.name}
                                </Typography>
                                <Typography component="h4" className={styles.position}>
                                    {person.position}
                                </Typography>

                            </Box>
                        </Box>
                    )
                })}
            </Box>
        )
    })


    return (
        <Box className={styles.testimonialsContainer}>
        <Box className={styles.testimonialsContainerInner}>

            <Typography component="h3" fontSize={"50px"} color={"primary"} fontWeight={"bold"} textAlign={"center"}>
                What Our Customer Says
            </Typography>
            <Typography component="p" fontSize={"20px"} color={"primary"} textAlign={"center"}>
                Our customers love ScreenStak™. Here’s what they have to say.
            </Typography>

            <Carousel
                showArrows={(usersOnScreenAtTheSameTime === peopleReviews.length) ? false : true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={false} // small circles at the bottom
                autoPlay={true}
                interval={20000}
                transitionTime={1000}
                renderArrowPrev={(onClickHandler, hasPrev, label) => {
                    return (usersOnScreenAtTheSameTime !== peopleReviews.length) && (
                        <IconButton size={"large"} onClick={onClickHandler} title={label}>
                            <ArrowBackIos/>
                        </IconButton>
                    )
                }}
                renderArrowNext={(onClickHandler, hasNext, label) => {
                    return (usersOnScreenAtTheSameTime !== peopleReviews.length) && (
                        <IconButton size={"large"} onClick={onClickHandler} title={label}>
                            <ArrowForwardIos/>
                        </IconButton>
                    )
                }}
                className={styles.carousel}
            >
                {testimonials}
            </Carousel>
        </Box>
        </Box>
    )
}