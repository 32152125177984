import styles from "../../styles/Home.module.scss";
import {Box, Button, Typography} from "@mui/material";
import Image from "next/image";

const GetInTouch = () => {
    return (
        <Box className={styles.getStartedContainer}>
            <Box className={styles.innerContainer}>
                <Box className={styles.text}>
                    <Typography variant={"body1"} className={styles.getInTouch}>Get in touch</Typography>
                    <Typography variant={"h2"}>Elevating Digital Experiences</Typography>
                    <Typography variant={"body2"} className={styles.description}>
                        Simply use the ScreenStak™ app on any computing device and build a larger display so that you can see the bigger picture. No need to do Pinch and Zoom and loose your context anymore.
                    </Typography>

                    <Box className={styles.controls}>
                        {/*<Button className={styles.bookDemo} variant="contained" size="large" href="/book-a-demo">Book a Demo</Button>*/}
                        {/*<Button className={styles.contactUs} variant="contained" size="large" href="/contact">Contact Us</Button>*/}
                        <Button className={styles.bookDemo} variant="contained" size="large" href="/contact">Contact Us</Button>
                    </Box>
                </Box>
                <Box className={styles.image}>
                    <Image priority={true} src={"/images/lets-get-started.png"} width={720} height={630}
                           alt={"test"}/>
                </Box>
            </Box>
        </Box>
    )
}

export default GetInTouch;