import {Box, Button, Slide, Typography, useTheme} from "@mui/material";
import PageContainer from "../layouts/PageContainer";
import styles from '../../styles/Home.module.scss'
import Link from "next/link";
import Image from "next/image";
import {useIntl} from "react-intl";

export default function HomeIntro() {
    const intl = useIntl();
    const theme = useTheme();

    return (
        <Box className={styles.headerInto}>
            <PageContainer overflow={"visible"}>
                <Box className={styles.headerInfoInner}>
                    <Box className={styles.headerInfoLeft}>

                        <Typography className={styles.primaryText} variant={"h1"}>
                            ScreenStak™ multiple smartphones
                        </Typography>

                        <Typography className={styles.mainTextDescription} variant={"h2"}>
                            Bring a few mobile phones close to each other. ScreenStak™ app will instantaneously combine them into an unified display. You will be able to see the bigger picture.
                        </Typography>

                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <Button variant={"outlined"} component={Link} href={'/features'}
                                    title={'Features list for product'}
                                    className={styles.learnMoreBtn}>
                                {intl.formatMessage({id: "page.read_more"})}
                            </Button>
                        </Box>
                    </Box>
                    <Box className={styles.headerInfoRight}>
                        <Image priority={true} src={'/images/4combiners.png'} width={1000} height={800}
                               alt={"ScreenStak™ combined screens"}/>
                    </Box>
                </Box>

            </PageContainer>
        </Box>
    )
}

